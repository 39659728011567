import BaseApi from './BaseApi'

class SettingsApi extends BaseApi {
  getEntry(company_id, key) {
    const url = this.createUrl(company_id, key)
    return this.get(url)
  }

  updateEntry(company_id, key, value) {
    const url = this.createUrl(company_id, key)
    return this.post(url, { value })
  }

  createUrl(company_id, key) {
    return ['companies', company_id, 'settings', key].filter(Boolean).join('/')
  }
}

export default new SettingsApi()
