<template>
  <div>
    <v-card class="upload-card mb-2" flat>
      <v-card-title>
        <h3>Neues Zahlungsjahr erstellen</h3>
        <v-spacer />
        <company-selector />
      </v-card-title>
      <v-card-text v-if="errorMessage">
        <validation-errors :errors="errorMessage" />
      </v-card-text>
      <v-card-text>
        Erzeugt ein neues Zahlungsjahr für die Firma
        <strong>{{ currentCompany.name }}</strong
        >. Hierbei wird das eingegebene Jahr <strong>{{ year }}</strong> als
        Grundlage genommen, die Summe aller Zahlungen geviertelt und in die
        Vorauszahlungen des Jahres
        <strong>{{ createdYear }}</strong> eingetragen.
      </v-card-text>
      <v-card-text>
        <yearpicker label="Jahr" v-model="year" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :loading="loading" @click="createPaymentYear"
          >Zahlungsjahr erzeugen</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card class="upload-card mb-2" flat>
      <v-card-title>
        <h3>Standard Zahlungsjahr {{ currentCompany.name }}</h3>
      </v-card-title>
      <v-card-text>
        Wenn in Dokumenten des Posteingangs kein Veranlagungsjahr gefunden wird,
        dann wird für die Firma
        <strong>{{ currentCompany.name }}</strong>
        standardmäßig dieses Jahr dem Dokument zugeordnet.
      </v-card-text>
      <v-card-text>
        <yearpicker label="Jahr" v-model="defaultPaymentYear" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="storeDefaultDocumentYear"
          >Veranlagungsjahr für {{ currentCompany.name }} speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Yearpicker from '@/components/dates/Yearpicker'
import serviceApi from '@/api/serviceApi'
import settingsApi from '@/api/settingsApi'
import CompanySelector from '@/components/CompanySelector'
import ValidationErrors from '@/components/ValidationErrors'

export default {
  name: 'NewPaymentYear',
  components: { ValidationErrors, CompanySelector, Yearpicker },
  data() {
    return {
      year: new Date().getFullYear(),
      defaultPaymentYear: new Date().getFullYear(),
      loading: false,
      errorMessage: null,
    }
  },
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany
    },
    createdYear() {
      const y = Number(this.year)
      return y + 1
    },
  },
  methods: {
    async createPaymentYear() {
      this.loading = true
      try {
        await serviceApi.createPreFilledPaymentYear(
          this.currentCompany.id,
          this.year
        )
        this.$root.setSnackbar(
          'success',
          `Zahlungsjahr ${this.createdYear} wurde angelegt.`
        )
      } catch (e) {
        this.errorMessage = e.errorObject
      }
      this.loading = false
    },
    async storeDefaultDocumentYear() {
      const year = this.defaultPaymentYear
      try {
        await settingsApi.updateEntry(
          this.currentCompany.id,
          `defaultDocumentYear`,
          year
        )
        this.$root.setSnackbar(
          'success',
          `Veranlagungsjahr für ${this.currentCompany.name} wurde auf ${year} gesetzt`
        )
      } catch (e) {
        this.$root.setSnackbar('error', `Fehler beim Speichern`)
      }
    },
  },
  watch: {
    currentCompany: {
      immediate: true,
      async handler() {
        const { value } = await settingsApi.getEntry(
          this.currentCompany.id,
          'defaultDocumentYear'
        )
        this.defaultPaymentYear = value
      },
    },
  },
}
</script>

<style scoped></style>
