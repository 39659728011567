import BaseApi from './BaseApi'
import { mimes } from '@/api/mimes'

class ServiceApi extends BaseApi {
  createPreFilledPaymentYear(company_id, year) {
    return this.post(
      `companies/${company_id}/paymentData/createPreFilledPaymentYears`,
      {
        year,
      }
    )
  }

  createDump() {
    return this.post(`/dump/create`)
  }

  getDump(dumpQueue) {
    return this.get(`/dump/queues/${dumpQueue}`)
  }

  getAllDumps() {
    return this.get(`/dump/queues`)
  }

  downloadDump(dumpQueue, fileName, mime = mimes.gz) {
    const url = `/dump/queues/${dumpQueue}/${fileName}/download`
    const method = 'get'
    return this.downloadAsFile({ url, method }, mime, fileName)
  }

  getLogs(page) {
    return this.get(`logs?page=${page}`)
  }

  getRoles() {
    return this.get(`/permissions/getRoles`)
  }

  updateRoles(id, roles = []) {
    return this.put(`/permissions/${id}/update`, { roles })
  }
}

export default new ServiceApi()
